import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import GoogleSignInButton from "components/auth/GoogleSigninButton";
import FacebookSignInButton from "components/auth/FacebookSigninButton";

const Login = () => {
  const navigate = useNavigate();
  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (auth.loggedIn) {
      navigate("/onboarding/upgrade");
    }
  }, [auth.loggedIn]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://uploads-ssl.webflow.com/620e74f53cbc9b619025b83f/621d08226699cb1333dbd41d_pexels-oleg-magni-2733663-p-3200.jpeg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Sign in or create an account
          </Typography>
          <Typography align="center">
            The safest way to convert new Facebook Group Members <br /> into
            Leads in less than 15 seconds.
          </Typography>
          <Box mt={4}>
            <GoogleSignInButton next="/onboarding/upgrade" />
          </Box>
          <Box mt={2}>
            <FacebookSignInButton next="/onboarding/upgrade" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
