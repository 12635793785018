import React from "react";
import {
  CreditCardIcon,
  UserCircleIcon,
  UserGroupIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { classNames } from "helpers/classnames";
import { Link } from "react-router-dom";

type Props = {
  activeTab: "groups" | "billing" | "integrations";
};

const SubNavigation = ({ activeTab }: Props) => {
  return (
    <nav className="space-y-1">
      <Link
        to="/account/groups"
        className={classNames(
          activeTab === "groups"
            ? "border-socialtribes text-socialtribes "
            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
          "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
        )}
      >
        <UserGroupIcon
          className={classNames(
            activeTab === "groups"
              ? "text-socialtribes"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
        <span className="truncate">Groups</span>
      </Link>
      <Link
        to="/account/billing"
        className={classNames(
          activeTab === "billing"
            ? "border-socialtribes text-socialtribes "
            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
          "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
        )}
      >
        <CreditCardIcon
          className={classNames(
            activeTab === "billing"
              ? "text-socialtribes"
              : "text-gray-400 group-hover:text-gray-500",
            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
        <span className="truncate">Billing</span>
      </Link>
      <a
        href="https://www.socialtribes.io/help-center"
        target="_blank"
        className={classNames("border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
        )}
      >
        <ViewGridAddIcon
          className={classNames(
            "text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
        <span className="truncate">Help Center</span>
      </a>
    </nav>
  );
};

export default SubNavigation;
