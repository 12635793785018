import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../config/firebase";

export const getUserById = async (uid: string) => {
  const userRef = await getDoc(doc(db, "users", uid));
  const user: any = userRef.data();
  return { ...user, premiumUntil: user.premiumUntil.seconds, uid: userRef.id };
};

export const watchUserDoc = async (uid, dispatcher) => {
  const unsub = onSnapshot(doc(db, "users", uid), (doc) => {});
};
