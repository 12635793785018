import React, { useState } from "react";
import { useSelector } from "react-redux";
import Page from "../../../components/utils/Page";
import { Disclosure, Switch } from "@headlessui/react";
import SubNavigation from "../../../components/account/SubNavigation";
import { classNames } from "../../../helpers/classnames";

const Integrations = (props) => {
  const auth = useSelector((state: any) => state.auth);

  return (
    <Page>
      <React.Fragment>
        <Disclosure
          as="div"
          className="relative bg-socialtribes pb-32 overflow-hidden"
        >
          <header className="relative py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-white">Integrations.</h1>
            </div>
          </header>
        </Disclosure>

        <main className="relative -mt-32">
          <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="py-6 lg:col-span-3">
                  <SubNavigation activeTab="integrations" />
                </aside>

                <form
                  className="divide-y divide-gray-200 lg:col-span-9"
                  action="#"
                  method="POST"
                >
                  {/* Profile section */}
                  <div className="py-6 px-4 sm:p-6 lg:pb-8">
                    <div>
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Integrations are coming soon!
                      </h2>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    </Page>
  );
};

export default Integrations;
