import { getApideckSessionToken } from "components/apideck";
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Dashboard from "components/base/Dashboard";
import { cards } from "components/dashboard/cards";
import { useDispatch } from "react-redux";
import { setApideckSessionToken } from "redux/auth";
import { SessionResponse } from "types";

const Account = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fixApiDeckToken = async () => {
      const { data } = ((await getApideckSessionToken()) as unknown) as Record<
        string,
        SessionResponse
      >;
      console.log("getting Api Deck token!");
      dispatch(setApideckSessionToken(data.session_token));
    };
    fixApiDeckToken().then();
  }, [dispatch]);
  return (
    <Dashboard>
      <Typography variant="h4">
        Hi there! <br /> <br />
      </Typography>
      <Grid container spacing={3}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {card}
          </Grid>
        ))}
      </Grid>
    </Dashboard>
  );
};

export default Account;
