import {
  doc,
  updateDoc,
  arrayUnion,
  setDoc,
  deleteField,
} from "firebase/firestore";
import { db } from "config/firebase";

export const addNewGroupToUser = async (uid, slug, googleSheetsUrl) => {
  const userRef = doc(db, "users", uid);

  await updateDoc(userRef, {
    [`groupSettings.${slug}`]: {
      sheet: googleSheetsUrl,
    },
  });
};

export const deleteGroupFromUser = async (uid, slug) => {
  const userRef = doc(db, "users", uid);

  await updateDoc(userRef, {
    [`groupSettings.${slug}`]: deleteField(),
  });
};
