import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {CardHeader, CardMedia, Link} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {CHROME_EXTENSION_URL} from "const";
import React from 'react';

const ChromeExtensionCard = (props) => {
    return (
        <Card sx={{minWidth: 275}}>

            <CardHeader
                title="Download SocialTribes"
            />
            <CardMedia
                component="img"
                height="88"
                sx={{height: 100}}
                image="https://img.generation-nt.com/chrome-web-store_01F0014B01665320.png"
                alt="Available in the Chrome Web Store"
            />
            <CardContent>
                <Typography variant="body2">
                    To get started with SocialTribes, install the SocialTribes Chrome Extension.
                </Typography>
            </CardContent>
            <CardActions>
                <Button component={Link} href={CHROME_EXTENSION_URL}>Install Now</Button>
            </CardActions>
        </Card>
    );
}

export default ChromeExtensionCard;
