import React, { useState } from "react";
import { Button } from "@mui/material";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { useDispatch } from "react-redux";
import { logUserOut } from "../../redux/auth";

interface Props {
  navigateTo?: string;
}

const Logout = ({ navigateTo = "/" }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = async () => {
    await signOut(auth);
    dispatch(logUserOut());
    navigate(navigateTo);
  };

  return (
    <div>
      <Button onClick={logout}>Logout</Button>
    </div>
  );
};

export default Logout;
