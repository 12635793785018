import CardHeader from "@mui/material/CardHeader";
import useTheme from "@mui/material/styles/useTheme";
import Switch from "@mui/material/Switch";
import React, { useState } from "react";
import { createCheckout } from "components/payment/payment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";

type Plan = {
  name: string;
  description: string;
  priceMonthly: string;
  priceYearly: string;
  stripeIdMonthly: string | undefined;
  stripeIdYearly: string | undefined;

  buttonVariant: "contained" | "outlined";
  features: string[];
};

const plans: Plan[] = [
  {
    name: "Basic",
    description: "The easiest way to get started with SocialTribes",
    priceMonthly: "$ 9",
    priceYearly: "$ 90",
    stripeIdMonthly: process.env.REACT_APP_StarterMonthlyStripeId,
    stripeIdYearly: process.env.REACT_APP_StarterYearlyStripeId,
    buttonVariant: "outlined",
    features: [
      "7 days free trial",
      "Manage unlimited amount of Facebook groups",
      "Unlimited approvals",
      "Unlimited amount of admins & moderators",
      "Send member requests to Google Sheets",
      "Live chat support",
    ],
  },
  {
    name: "Expert",
    description: "Getting the most out of SocialTribes",
    priceMonthly: "$ 39",
    priceYearly: "$ 390",
    stripeIdMonthly: process.env.REACT_APP_ExpertMonthlyStripeId,
    stripeIdYearly: process.env.REACT_APP_ExpertYearlyStripeId,
    buttonVariant: "contained",
    features: [
      "7 days free trial",
      "Manage unlimited amount of Facebook groups",
      "Unlimited approvals",
      "Unlimited amount of admins & moderators",
      "Send member requests to Google Sheets",
      "Live chat support",
      "Zapier integration (coming soon)",
      "Native CRM integrations (coming soon)",
    ],
  },
  {
    name: "Scale",
    description: "Let us help you grow your Facebook group",
    priceMonthly: "$ 199",
    priceYearly: "$ 1990",
    stripeIdMonthly: process.env.REACT_APP_ScaleMonthlyStripeId,
    stripeIdYearly: process.env.REACT_APP_ScaleYearlyStripeId,
    buttonVariant: "outlined",
    features: [
      "7 days free trial",
      "Manage unlimited amount of Facebook groups",
      "Unlimited approvals",
      "Unlimited amount of admins & moderators",
      "Send member requests to Google Sheets",
      "Live chat support",
      "Zapier integration (coming soon)",
      "Native CRM integrations (coming soon)",
      "Personalized onboarding & set-up",
      "Monthly Facebook growth call",
    ],
  },
];

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);
  const theme = useTheme();
  const handleClick = async (stripePrice: any) => {
    const checkout = await createCheckout(stripePrice);
    window.location.href = checkout.data.url;
  };

  return (
    <Box
      component={"div"}
      sx={{
        background: `linear-gradient(180deg, ${theme.palette.primary.dark} 65%, #fff 35%);`,
      }}
    >
      <Container maxWidth="lg">
        <Box py={8} textAlign="center">
          <Box mb={3} color="common.white">
            <Container maxWidth="sm">
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="white">
                  Get started{" "}
                </Typography>
                <Typography variant="h3" component="span">
                  with SocialTribes
                </Typography>
              </Typography>
              <Typography variant="subtitle1" color="white" paragraph={true}>
                The first 7 days are free.
              </Typography>

              <div>
                <Typography variant="subtitle1" component="span">
                  Monthly
                </Typography>
                &nbsp;{" "}
                <Switch
                  name="checkbox"
                  color="warning"
                  checked={isYearly}
                  onChange={() => setIsYearly((prev) => !prev)}
                />{" "}
                &nbsp;
                <Typography variant="subtitle1" component="span">
                  Annual
                </Typography>
              </div>
            </Container>
          </Box>
          <Grid container spacing={3}>
            {plans.map((plan) => (
              <Grid item xs={12} md={4}>
                <Card variant="outlined">
                  <CardHeader title={plan.name} mt={2}></CardHeader>
                  <CardContent>
                    <Box px={1}>
                      <Typography
                        variant="h3"
                        component="h2"
                        gutterBottom={true}
                      >
                        {isYearly ? plan.priceYearly : plan.priceMonthly}
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          component="span"
                        >
                          / {isYearly ? "yr" : "mo"}
                        </Typography>
                      </Typography>
                      {plan.features.map((feature) => (
                        <Typography
                          color="textSecondary"
                          variant="subtitle1"
                          component="p"
                          textAlign="left"
                        >
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                    <Box mt={4}>
                      {" "}
                      <Button
                        variant={plan.buttonVariant}
                        color="primary"
                        onClick={() =>
                          handleClick(
                            isYearly
                              ? plan.stripeIdYearly
                              : plan.stripeIdMonthly
                          )
                        }
                      >
                        Select plan
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;
