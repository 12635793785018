import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserById } from "components/auth/auth";
import { User } from "types";

export const getUserData = createAsyncThunk(
  "auth/getUserData",
  async ({ uid }: { uid: string }) => {
    return (await getUserById(uid)) as User;
  }
);

interface AuthState {
  loggedIn: boolean;
  loading: boolean;
  user: User;
  apiDeckSessionToken: string | null
}

const initialState: AuthState = {
  loggedIn: false,
  loading: false,
  user: {
    groupSettings: {},
  },
  apiDeckSessionToken: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logUserOut: (state) => {
      state.loggedIn = false;
      state.user = { groupSettings: {} };
    },
    updateUserDoc: (state, { payload }) => {
      state.loggedIn = true;
      state.user = { groupSettings: {}, ...payload };
    },
    setApideckSessionToken: (state, {payload}) => {
      state.apiDeckSessionToken = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.loggedIn = true;
        state.user = payload;
      })
      .addCase(getUserData.pending, (state, { payload }) => {
        state.loading = true;
      });
  },
});

export const { logUserOut, updateUserDoc, setApideckSessionToken } = authSlice.actions;
export default authSlice.reducer;
