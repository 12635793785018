import React from 'react';
import {CardHeader, Link} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";


const IntegrationsCard = (props) => {
    return (
        <Card sx={{minWidth: 275}}>
            <CardHeader
                title="Integrations"
            />
            <CardContent>
                <Typography variant="body2">
                    With SocialTribes you can send the member requests from your group directly to your CRM.
                </Typography>
                <Typography variant="body2"><br/>
Connect directly with one of our native CRM connections or connect through Zapier and connect with any CRM you have.

                </Typography>
                <Typography variant="body2"><br/>
Let us know if you need any help with integrating your CRM.
                </Typography>
            </CardContent>
            <CardActions>
                <Button component={Link} target="_blank" href="https://www.socialtribes.io/integrations?ref=dashboard">Read More</Button>
            </CardActions>
        </Card>
    );
}

export default IntegrationsCard;
