import CssBaseline from "@mui/material/CssBaseline";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
// import {getApideckSessionToken} from "components/apideck";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { auth, db } from "config/firebase";
import { SessionResponse } from "types";
import routes from "./config/routes";
import Center from "./components/utils/Center";
import AuthChecker from "./components/auth/AuthChecker";
import { useDispatch } from "react-redux";
import { setApideckSessionToken, updateUserDoc } from "redux/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";

declare module "@mui/material/styles" {
  interface ThemeOptions {
    brands: {
      google: React.CSSProperties["color"];
      facebook: React.CSSProperties["color"];
    };
  }
}

const theme = createTheme({
  brands: {
    google: "#DB4437",
    facebook: "#4267B2",
  },
  palette: {
    primary: {
      main: "rgb(68,87,255)",
    },
  },
});

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const unsub = onSnapshot(doc(db, "users", user.uid), (doc) => {
          const userData = doc.data();
          const newUser = {
            ...doc.data(),
            premiumUntil: userData?.premiumUntil?.seconds || 0,
            uid: user.uid,
          };
          dispatch(updateUserDoc(newUser));
        });
      }
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <Center>
        <CircularProgress />
      </Center>
    );

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  route.protected ? (
                    <AuthChecker>
                      <route.component />
                    </AuthChecker>
                  ) : (
                    <route.component />
                  )
                }
              />
            ))}
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

export default App;
