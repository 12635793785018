import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {CardHeader, CardMedia, Link} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {CHROME_EXTENSION_URL} from "const";
import React from 'react';

const AddingFirstGroupCard = (props) => {
    return (
        <Card sx={{minWidth: 275}}>

            <CardHeader
                title="How to add a group"
            />
            <CardMedia
                component="div"
                sx={{height: 100, position: 'relative', paddingTop: '66%'}}
            >
                <iframe src="https://www.loom.com/embed/7d9e7d1c9f154025813f2a3a88506bfa" frameBorder="0"
    allowFullScreen={true} style={{position: "absolute", width: '100%', height: '100%', left: 0, right: 0, top: 0, bottom: 0}} />
            </CardMedia>
            <CardContent>
                <Typography variant="body2">
                    To help you add your first group to SocialTribes, Ricardo created this handy walkthrough video.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default AddingFirstGroupCard;
