import React, {useState} from 'react';
import {Vault} from "@apideck/react-vault";
import { useSelector } from "react-redux";
import {RootState} from "redux/store";
import Button from '@mui/material/Button';
import '@apideck/react-vault/dist/styles.css';


const VaultButton = (props) => {
    const [openVault, setOpenVault] = useState(false)
    const {apiDeckSessionToken} = useSelector((state: RootState) => state.auth);

    const toggleVault = () => {
        setOpenVault(!openVault)
    }

    if(!apiDeckSessionToken) return <div><p>Waiting for Apideck Session token...</p></div>

    return (
        <React.Fragment>
            <Button  onClick={toggleVault} >Connect to CRM</Button>
            <Vault token={apiDeckSessionToken} open={openVault} onClose={toggleVault} />
        </React.Fragment>
    )

}

export default VaultButton;
