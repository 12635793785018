import GoogleIcon from "@mui/icons-material/Google";
import {Button} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, Providers } from "config/firebase";
import FacebookIcon from "@mui/icons-material/Facebook";
import {toast} from "react-toastify";

type Props = {
  next: string;
};

const FacebookSignInButton = ({ next }: Props) => {
  const navigate = useNavigate();

  const signInWithFacebook = () => {
    signInWithPopup(auth, Providers.facebook).then(() => {
      navigate(next);
      toast.success('Welcome!')
    }).catch(e => {
      toast.error(`Error: ${e.toString()}`)
    })
  };

  return (<Button onClick={signInWithFacebook}  size="large" variant="contained"  startIcon={<FacebookIcon />}>
    Sign in with Facebook
  </Button>)

};

export default FacebookSignInButton;
