import {Button} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import {toast} from "react-toastify";
import { auth, Providers } from "config/firebase";
import GoogleIcon from "@mui/icons-material/Google";

type Props = {
  next: string;
};

const GoogleSignInButton = ({ next }: Props) => {
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, Providers.google).then(() => {
      navigate(next);
      toast.success('Welcome!')
    }).catch(e => {
      toast.error(`Error: ${e.toString()}`)
    })
  };

  return (<Button onClick={signInWithGoogle}  size="large" variant="contained" color="error"  startIcon={<GoogleIcon />}>
    Sign in with Google
  </Button>)

};

export default GoogleSignInButton;
