import Tests from "screens/internal/Tests";
import Home from "../screens/Home";
import Add from "../screens/account/groups/Add";
import Groups from "../screens/account/groups/Groups";
import Billing from "../screens/account/billing/Billing";
import Integrations from "../screens/account/integrations/Integrations";
import Group from "../screens/account/groups/Group";
import OnboardLogin from "../screens/onboarding/Login";
import OnboardPricing from "../screens/onboarding/Pricing";
import OnboardLicenseKey from "../screens/onboarding/LicenseKey";
import Account from "../screens/account/Account";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "",
    component: Home,
    name: "Home Screen",
    protected: false,
  },
  {
    path: "/account",
    component: Account,
    name: "Account Screen",
    protected: false,
  },
  {
    path: "/account/groups",
    component: Groups,
    name: "Groups",
    protected: true,
  },
  {
    path: "/account/groups/:slug",
    component: Group,
    name: "Group",
    protected: true,
  },
  {
    path: "/account/groups/add",
    component: Add,
    name: "Add Group",
    protected: true,
  },
  {
    path: "/account/billing",
    component: Billing,
    name: "Billing",
    protected: true,
  },
  {
    path: "/account/integrations",
    component: Integrations,
    name: "Integrations",
    protected: true,
  },
  {
    path: "/onboarding",
    component: OnboardLogin,
    name: "Onboardlogin",
    protected: false,
  },
  {
    path: "/onboarding/upgrade",
    component: OnboardPricing,
    name: "OnboardPricing",
    protected: true,
  },
  {
    path: "/onboarding/license",
    component: OnboardLicenseKey,
    name: "OnboardLicenseKey",
    protected: true,
  },
  {
    path: "/internal/__tests",
    component: Tests,
    name: "internalTests",
    protected: true,
  },
];

export default routes;
