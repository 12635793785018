import React from "react";
import Header from "../Header";

type Props = {
  children: any;
};

const Page = (props: Props) => {
  return (
    <div>
      <Header />
      {props.children}
    </div>
  );
};

export default Page;
