import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Dashboard from "components/base/Dashboard";
import {cards} from "components/dashboard/cards";
import GroupCard from "components/groups/GroupCard";
import React, {useState} from "react";
import Page from "components/utils/Page";
import {Disclosure} from "@headlessui/react";
import SubNavigation from "components/account/SubNavigation";
import {ChevronRightIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import LicenseKey from "components/auth/LicenseKey";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import {RootState} from "redux/store";

const Groups = (props) => {
    const auth = useSelector((state: RootState) => state.auth);

    return (
        <Dashboard>
            <Typography variant="h4">Your groups<br/> <br/></Typography>
            <Grid container spacing={3}>


                {Object.entries(auth.user.groupSettings).map(
                    ([slug, group]) => {
                        return (
                            <Grid item xs={12} sm={6} md={3} key={slug} >
                                <GroupCard group={group} slug={slug}/>
                            </Grid>

                        );
                    }
                )}
            </Grid>
        </Dashboard>
    );
};

export default Groups;

