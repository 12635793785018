import React from 'react';
import AddingFirstGroupCard from "./AddingFirstGroupCard";
import HowToGetStarted from "./HowToGetStarted";
import LicenseKeyCard from "./LicenseKeyCard"
import ChromeExtensionCard from "./ChromeExtensionCard"
import IntegrationsCard from "./IntegrationsCard"

export const cards = [
    <HowToGetStarted/>,
    <AddingFirstGroupCard/>,
    <LicenseKeyCard />,
    <ChromeExtensionCard/>,
    <IntegrationsCard/>,


]
