import {CardHeader, CardMedia, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {CHROME_EXTENSION_URL} from "const";
import {Link} from "react-router-dom";

import React from 'react';
import {Group} from "types";

type Props = {
    group: Group,
    slug: string
}
const GroupCard = ({group, slug}: Props) => {
    return (
        <Card sx={{minWidth: 275}}>

            <CardMedia
                component="div"
                sx={{height: 140}}
                className={`card-${slug[0]}`}
            />

            <CardHeader
                title={slug}
            />
            <CardContent>
                <Typography variant="body2">
Manage "{slug}" and connect it with a Google Sheet, Zapier, and more!
                </Typography>
            </CardContent>
            <CardActions>
                <Button>
                    <Link
                        to={`/account/groups/${slug}`}
                        state={{group}}
                    >Manage group</Link>
                </Button>
            </CardActions>
        </Card>
    );
}

export default GroupCard;
