import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Box, Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VaultButton from "components/apideck/VaultButton";
import Dashboard from "components/base/Dashboard";
import {steps} from "components/groups/steps";
import {GOOGLE_SHEET_TEMPLATE_URL} from "const";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {toast} from "react-toastify";
import { useSearchParam } from "react-use";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { addNewGroupToUser } from "components/groups/groups";

const Add = (props: any) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const auth = useSelector((state: any) => state.auth);
  const slug = useSearchParam("slug") as string;
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) navigate("/account/groups");
  }, [slug]);

  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    await addNewGroupToUser(auth.user.uid, data.slug, data.googleSheetUrl);
    toast.success(`Added new group ${slug}`)
    navigate("/account/groups");
  };

  return (
      <Dashboard>
        <Typography variant="h4">Adding "{slug}"<br/> <br/></Typography>
        <form
            onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" value={slug} {...register("slug")} />
          {/* Google Sheets section */}

          <Typography variant="h5">Google Sheets <br/></Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {/*<Stepper activeStep={activeStep} orientation="vertical">*/}
            {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                      optional={
                        index === 0 ? (
                            <Typography variant="caption">
                              <a href={GOOGLE_SHEET_TEMPLATE_URL}
                                 target="_blank">Open Google Sheet Template <OpenInNewIcon sx={{fontSize: 12}}/></a>
                            </Typography>
                        ) : null
                      }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    {step.extraContent}
                    {index === 3 && <div>
                      <TextField
                          InputLabelProps={{ shrink: true }}
                          {...register("googleSheetUrl")} label="Google Sheets URL" variant="outlined"  margin="normal" sx={{minWidth: 400}}/>

                    </div>}
                    <Box sx={{ mb: 2 }}>
                      <div>
                        {index === steps.length - 1 ?  <Button type="submit"
                                                               sx={{ mt: 1, mr: 1 }}
                        >
                          Finish and save Google Sheet
                        </Button> :  <Button
                            onClick={() => {setActiveStep(index + 1)}}
                            sx={{ mt: 1, mr: 1 }}
                        >
                          Continue
                        </Button>}


                      </div>
                    </Box>
                  </StepContent>
                </Step>
            ))}
          </Stepper>
          <Divider/>
          <br/>
          <Typography variant="h5">Integrate with your CRM<br/> <br/></Typography>
          <Typography>We use Apideck to securely connect to your CRM. </Typography>
          <br/>
          <VaultButton/>

        </form>
      </Dashboard>
  );
};

export default Add;
