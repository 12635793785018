import {functions} from "config/firebase";
import {httpsCallable} from "firebase/functions";

export const getApideckSessionToken = async () => {
    const handleGetApideckSessionToken = httpsCallable(
        functions,
        "handleGetApideckSessionToken"
    );
    return await handleGetApideckSessionToken();
};
