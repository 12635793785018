import { initializeApp, getApp } from "firebase/app";
import { firebaseConfig } from "./firebase.config";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
export const Providers = {
  google: new GoogleAuthProvider(),
  facebook: new FacebookAuthProvider(),
};

export const db = getFirestore();

export const functions = getFunctions(getApp(), "us-central1");
if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5123);
}
