import { httpsCallable } from "firebase/functions";
import { functions } from "config/firebase";

export const createCheckout = async (stripePriceId: string): Promise<any> => {
  const handleCreateSubscription = httpsCallable(
    functions,
    "handleCreateSubscription"
  );
  return await handleCreateSubscription({ stripePriceId });
};

export const createBillingPortalSession = async () => {
  const handleCreateBillingPortalSession = httpsCallable(
    functions,
    "handleCreateBillingPortalSession" 
  );
  return await handleCreateBillingPortalSession();
};
