import {CardHeader, CardMedia, Link} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {CHROME_EXTENSION_URL} from "const";
import React from 'react';

const HowToGetStarted = (props) => {
    return (
        <Card sx={{minWidth: 275}}>

            <CardHeader
                title="How to get started"
            />
            <CardMedia
                component="div"
                sx={{height: 100, position: 'relative', paddingTop: '66%'}}
            >
                <iframe src="https://www.loom.com/embed/60f8a212dae040ab893109aadbc7189a" frameBorder="0"
                        allowFullScreen={true} style={{position: "absolute", width: '100%', height: '100%', left: 0, right: 0, top: 0, bottom: 0}} />
            </CardMedia>
            <CardContent>
                <Typography variant="body2">
                    Welcome to SocialTribes. To get started, watch this video by Ricardo!
                </Typography>
            </CardContent>
        </Card>
    );
}

export default HowToGetStarted;
