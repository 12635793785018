import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Dashboard from "components/base/Dashboard";
import {cards} from "components/dashboard/cards";
import React from "react";
import {RootState} from "redux/store";
import Page from "../../components/utils/Page";
import { useSelector } from "react-redux";

import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const LicenseKey = (props) => {
  const auth = useSelector((state: RootState) => state.auth);
  const { width, height } = useWindowSize()
  return (<Dashboard>
    <Typography variant="h4">Welcome aboard!<br/> <br/></Typography>
    <Grid container spacing={3}>
      {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {card}
          </Grid>
      ))}
    </Grid>
    <Confetti
        width={width}
        height={height}
    />
  </Dashboard>)
 
};

export default LicenseKey;
