import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Dashboard from "components/base/Dashboard";
import {cards} from "components/dashboard/cards";
import React, { useEffect, useState } from "react";
import Page from "../../../components/utils/Page";
import { Disclosure, Switch } from "@headlessui/react";
import SubNavigation from "../../../components/account/SubNavigation";
import { classNames } from "../../../helpers/classnames";
import { useSelector } from "react-redux";
import { createBillingPortalSession } from "../../../components/payment/payment";

const Billing = (props) => {
  useEffect(() => {
    createBillingPortalSession().then((r) => {
      // @ts-ignore
      document.location.href = r?.data.url;
    });
  }, []);

  return (
      <Dashboard>
        <Typography variant="h4">Loading your billing center <br/> <br/></Typography>
        <Typography variant="body1">We're contacting Stripe to connect you to your secure billing center, where you can edit and see all your payment details.</Typography>
        <Typography variant="body1">This might take a while. We'll automatically redirect you when it's ready.</Typography>
      </Dashboard>
  );
};

export default Billing;
