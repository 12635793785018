import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Dashboard from "components/base/Dashboard";
import {cards} from "components/dashboard/cards";
import React from 'react';

const Tests = (props) => {
    return (
        <Dashboard >
            <React.Fragment>
                <Typography variant="h4">Hi there! <br/> <br/></Typography>
                <Grid container spacing={3}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            {card}
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        </Dashboard>
    );
}

export default Tests;
