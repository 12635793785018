import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {GOOGLE_SHEET_TEMPLATE_URL} from "const";
import React from "react";

const openGoogleSheet = () => { window.open(
    GOOGLE_SHEET_TEMPLATE_URL
);}

export const steps = [
    {
        label: 'Copy Google Sheet',
        description: `Open the SocialTribes Google Sheet template and copy it to your own Google Drive.`,
        extraContent: <div>
            <Button
                onClick={openGoogleSheet}
        endIcon={<ContentCopyIcon />}
    >
    Copy Google Sheets
    </Button>
    </div>
},
{
    label: 'Make the Google Sheet shared',
        description:
    'In order to be able to add member requests to the new Google Sheet, it must have the right permissions. Followed the steps in the "Getting Started" tab in the Google Sheet.',
        extraContent: <div><Box component="img" sx={{maxWidth: 500}} src="https://uploads-ssl.webflow.com/620e74f53cbc9b619025b83f/622cb10e831230b0ac93fdf5_Screenshot%202022-03-12%20at%2018.39.25.png" alt="Follow the steps"/></div>

},
{
    label: 'Copy new Google Sheet URL',
        description:
    "Copy the link and come back here. Don't worry, we'll wait :)",
},
{
    label: 'Paste Google Sheet URL here',
        description: `Lastly, enter the Google Sheet URL here. Next time you extract member requests, we will also put them in this Google Sheet.`,

},
];
