import {CardHeader} from "@mui/material";
import React from 'react';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {CHROME_EXTENSION_URL} from "const";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {RootState} from "redux/store";


const LicenseKeyCard = () => {
    const auth = useSelector((state: RootState) => state.auth);

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(auth.user.licenseKey || "")
        toast.success("You successfully copied your license key")
    }
    return (
        <Card sx={{minWidth: 275}}>
            <CardHeader
                title="Your License Key"
            />
            <CardContent>
                <div>
                    <TextField label="Your License Key"
                               id="label"
                               defaultValue={auth.user.licenseKey}
                               sx={{marginTop: 1, marginBottom: 1}}
                               InputLabelProps={{ shrink: true }}
                               InputProps={{

                                   readOnly: true,
                                   endAdornment: <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={copyToClipboard}
                                           edge="end"
                                       >
                                           <ContentCopyIcon/>
                                       </IconButton>
                                   </InputAdornment>
                               }}

                               fullWidth/>
                </div>
                <Typography variant="body2">
                    Use the license key to authenticate in the <a href={CHROME_EXTENSION_URL} target="_blank">SocialTribes
                    Google Chrome Extension</a>.
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={copyToClipboard}>Copy license key</Button>
            </CardActions>
        </Card>
    );
}

export default LicenseKeyCard;
