import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  AnnotationIcon,
  ChatAlt2Icon,
  ChatAltIcon,
  DocumentReportIcon,
  HeartIcon,
  InboxIcon,
  MenuIcon,
  PencilAltIcon,
  QuestionMarkCircleIcon,
  ReplyIcon,
  SparklesIcon,
  TrashIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Logout from "./auth/Logout";
import { useSelector } from "react-redux";

const Header = () => {
  const auth = useSelector((state: any) => state.auth);

  return (
    <header>
      <Popover className="relative bg-white">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={auth.loggedIn ? "/account/groups" : "/"}>
              <span className="sr-only">Workflow</span>
              <img
                className="h-14 w-auto sm:h-10"
                src="/img/st_brand_lightmode_trimmed.png"
                alt=""
              />
            </Link>
          </div>
          {/*<Popover.Group as="nav" className="hidden md:flex space-x-10">*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="text-base font-medium text-gray-500 hover:text-gray-900"*/}
          {/*  >*/}
          {/*    Pricing*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="text-base font-medium text-gray-500 hover:text-gray-900"*/}
          {/*  >*/}
          {/*    Partners*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="text-base font-medium text-gray-500 hover:text-gray-900"*/}
          {/*  >*/}
          {/*    Company*/}
          {/*  </a>*/}
          {/*</Popover.Group>*/}
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {auth.loggedIn && <Logout />}
            {/*<a*/}
            {/*  href="/"*/}
            {/*  className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-purple-700 hover:to-indigo-700"*/}
            {/*>*/}
            {/*  Sign up*/}
            {/*</a>*/}
          </div>
        </div>
      </Popover>
    </header>
  );
};

export default Header;
